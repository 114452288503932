const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Competitions',
    noCategoriesText: 'There are currently no active competitions to apply for',
    createRequest: 'Create application',
    campaignPeriod: 'Application deadline',
    campaignRequests: 'Competition applications',
    campaignSettings: 'Competition settings',
    campaignRegForms: 'Application forms',
    campaignDescription: 'Competition description',
    campaignDescriptionEdit: 'Competition editing',
    campaignRegForm: 'Application {count, plural, one {form} other {forms}}',
    addCampaignRegFormButton: 'Add application form',
    newCampaignRegForm: 'New application form',
    editCampaignRegForm: 'Editing the "{formTitle}" application form',
    deleteCampaignRegForm: 'Delete application form',
    confirmDeleteCampaignRegForm: 'Are you sure you want to delete the application form "{formTitle}"?',
    templateForm: {
        regForms: 'Application forms',
    },
    requestFormSettingsTitle: 'Setting for application tabs',
    requestTabLabel: 'Application tab',
    createRequestError: 'Creation of applications by competition is not available',
};

export default campaign;