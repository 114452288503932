import en from '../../common/en';
import { recordToLang } from '../../utils';
import breadcrumbs from './breadcrumbs';
import campaign from './campaign';
import campaignRequest from './campaignRequest';
import common from './common';
import homePage from './homePage';
import requestLog from './requestLog';
import startPage from './startPage';

const lang: Record<string, string> = {
    ...en,
    ...recordToLang({
        startPage,
        breadcrumbs,
        campaign,
        campaignRequest,
        homePage,
        requestLog,
        common,
    })
};

export default lang;
