import ru from '../../common/ru';
import startPage from './startPage';
import { recordToLang } from '../../utils';

const lang: Record<string, string> = {
    ...ru,
    ...recordToLang({ startPage })
};

export default lang;
