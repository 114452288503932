import fonts from './fonts';
import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../themeTypes';

type ColorName =
    | 'black'
    | 'white'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'red'
    | 'green'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled'
    | 'blue'
    | 'blueDark'
    | 'blueMiddle'
    | 'blueLight'
    | 'blueLighter'
    | 'blueWhite';

const przColors: Record<ColorName, string> = {
    black: '#000000',
    white: '#ffffff',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    red: '#fb4d3d',
    green: '#008000',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
    blue: '#1B6195',
    blueDark: '#124265',

    blueMiddle: '#D6E3EC',
    blueLight: '#5C8EB3',
    blueLighter: '#AEC7D9',
    blueWhite: '#E7EFF4',
};

const przFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
    },
};

const przIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const przPalette: AppPalette = {
    main: przColors.greyMain,
    textMain: przColors.greyMain,
    textDark: przColors.black,
    mainMiddleLight: przColors.greyMiddleLight,
    mainLight: przColors.greyLight,
    mainDark: przColors.greyDark,
    mainContrast: przColors.white,
    mainContrastDarker: przColors.greyLighter,
    accent: przColors.blue,
    accentDark: przColors.blueDark,
    accentContrast: przColors.black,
    accentBackgroundGradient: `linear-gradient(-30deg, ${przColors.blue} 0%, ${przColors.blueDark} 100%)`,
    hover: przColors.blue,
    hoverInLists: przColors.greyWhite,
    red: przColors.red,
    green: przColors.green,
    campaignList: {
        backgroundCard: przColors.lighterBlue,
        selectedPaginationButton: przColors.lighterGrey,
    },
    table: {
        row: {
            even: przColors.white,
            odd: przColors.greyLighter,
        },
    },
    card: {
        header: {
            main: przColors.blueLight,
            color: przColors.white,
        },
    },
    appBar: {
        mainContrast: przColors.blue,
    },
    button: {
        accentContrast: przColors.white,
        disabled: przColors.disabled,
    },
    iconButton: {
        color: przColors.blue,
        hoverColor: przColors.blue,
        hoverBackgroundColor: przColors.blueWhite,
    },
    background: {
        textMain: przColors.black,
    },
    footer: {
        textMain: przColors.blueLighter,
        padding: 3.75,
    },
    tab: {
        backgroundColor: przColors.greyMiddleLight,
        color: przColors.blue,
        hoverBackgroundColor: przColors.blue,
        hoverColor: przColors.white,
    },
    panel: {
        markerBackgroundColor: 'rgba(53, 167, 255, 0.09)',
        markerColor: przColors.black,
    },

    accentContrastBackgroundGradient: przColors.white,
    mainBlue: przColors.blue,
    mainBlueMiddleLight: przColors.blueMiddle,
    mainBlueLight: przColors.blueLight,
    mainBlueDark: przColors.blueDark,
    mainBlueLighter: przColors.blueLighter,
    mainBlueWhite: przColors.blueWhite,
};

export default {
    fonts: przFonts,
    palette: przPalette,
    icons: przIcons,
} as ThemeOverrides;
