const campaignRequest: Record<string, Record<string, string> | string> = {
    registryColumns: {
        campaign: 'Конкурс',
    },
    printFormsTable: {
        crcCode: 'CRC-код',
    },
    errorTextForRequest:
        'Проверьте заполненность обязательных полей заявки и корректность введенных значений. Разделы, в которых содержатся ошибки, отмечены красным восклицательным знаком справа от анкеты заявки',
};

export default campaignRequest;
