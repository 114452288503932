import { HeaderLinksStore as HeaderLinksStoreICS } from '@platform/ics-front-core';
import { computed } from 'mobx';
import { injectable } from 'react-magnetic-di';

export class HeaderLinksStore extends HeaderLinksStoreICS {
    @computed
    get avgLinkWidth(): number {
        return 200;
    }
}

export const HeaderLinksStoreDI = injectable(HeaderLinksStoreICS, HeaderLinksStore);
