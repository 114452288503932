import overrides from '../common/common';
import { ThemeOverrides } from '../themeTypes';
import { AppPalette as ICSAppPalette } from '@platform/ics-front-core';

type RepAppPalette = ICSAppPalette & {
    mainBlue?: string;
    mainBlueMiddleLight?: string;
    mainBlueLight?: string;
    mainBlueDark?: string;
    mainBlueWhite?: string;
    mainBlueLighter?: string;
    repTextSecondaryHover?: string;
};

type RepColorName =
    | 'black'
    | 'white'
    | 'yellow'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'red'
    | 'blue'
    | 'brightBlue'
    | 'darkBlue'
    | 'lightBlue'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled';

const repColors: Record<RepColorName, string> = {
    black: '#000000',
    white: '#ffffff',
    yellow: '#ffff33',
    yellowDark: '#f5c722',
    // greyMain: '#63666a',
    greyMain: '#314349',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    // greyDark: '#314349',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    red: '#f04438',
    blue: '#0096df',
    brightBlue: 'rgba(0, 160, 224, .8)',
    darkBlue: '#0578b0',
    lightBlue: '#E5F5FC',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${repColors.darkBlue} 0%, ${repColors.darkBlue} 100%)`;

export const repPalette: RepAppPalette = {
    main: repColors.greyMain,
    textMain: repColors.greyMain,
    textDark: repColors.black,
    mainMiddleLight: repColors.greyMiddleLight,
    mainLight: repColors.greyLight,
    mainDark: repColors.greyDark,
    mainContrast: repColors.white,
    mainContrastDarker: repColors.greyLighter,
    accent: repColors.brightBlue,
    accentDark: repColors.brightBlue,
    accentContrast: repColors.black,
    accentBackgroundGradient: accentBackgroundGradient,
    hover: repColors.brightBlue,
    hoverInLists: repColors.greyWhite,
    red: repColors.red,
    green: repColors.blue,
    campaignList: {
        backgroundCard: repColors.lighterBlue,
        selectedPaginationButton: repColors.lighterGrey,
        buttonPaginationTextHover: repColors.black,
        selectedPaginationButtonBackgroundHover: repColors.greyMiddleLight,
        darkBackgroundHoverTextCard: repColors.greyWhite,
    },
    table: {
        row: {
            even: repColors.white,
            odd: repColors.greyLighter,
        },
    },
    card: {
        header: {
            main: repColors.lighterGrey,
            color: repColors.black,
        },
    },
    appBar: {
        mainContrast: repColors.lightBlue,
    },
    tooltip: {
        backgroundColor: repColors.black,
    },
    button: {
        accentContrast: repColors.white,
        disabled: repColors.disabled,
        textHover: repColors.blue,
    },
    iconButton: {
        color: repColors.brightBlue,
        hoverColor: repColors.white,
        hoverBackgroundColor: repColors.brightBlue,
    },
    background: {
        textMain: repColors.black,
    },
    footer: {
        textMain: repColors.greyMain,
        padding: 3.75,
    },
    tab: {
        backgroundColor: '#ececec',
        color: repColors.black,
        hoverBackgroundColor: repColors.brightBlue,
        hoverColor: repColors.white,
    },
    panel: {
        markerBackgroundColor: repColors.lightBlue,
        markerColor: repColors.black,
    },
    accentContrastBackgroundGradient: repColors.white,
    mainBlue: repColors.blue,
    mainBlueMiddleLight: repColors.lighterBlue,
    mainBlueLight: repColors.lightBlue,
    mainBlueDark: repColors.brightBlue,
    mainBlueLighter: repColors.blue,
    mainBlueWhite: repColors.lighterBlue,

    repTextSecondaryHover: repColors.darkBlue,
};

export default {
    ...overrides,
    palette: repPalette,
} as ThemeOverrides;
