export default {
    startPageLinks: false,
    startPage: false,
    loginWithoutRegister: false,
    logo: {
        isEnabled: true,
        locales: {
            ru: {
                src: require(`../resources/images/rep-logo.svg`),
                alt: 'ВНИИР',
            },
        },
        style: { maxHeight: 60, marginTop: '5px' },
        withText: false,
        mdLogo: false,
    },
    customBackground: {
        isEnabled: true,
    },
    helpIcon: false,
    notificationIcon: false,
    phoneNumber: false,
    startPageDefaultText: false,
    esiaLogin: false,
    showCreateRequestButton: false,
    showRequestsLamp: false,
    showCampaignPeriod: true,
    loginDialogClose: false,
    loginDialogAlert: true,
    yaMetrika: true,
    modularNavigation: false,
    email: true,
    changeLanguage: true,
};
