const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Сборы сведений',
    titleCreateCampaignPage: 'Создание конкурса',
    noCategoriesText: 'В данный момент нет активных конкурсов для подачи заявки',
    newCampaign: 'Новый конкурс',
    createCampaign: 'Создать конкурс',
    editCampaign: 'Редактирование конкурса',
    campaignRequests: 'Предоставленные сведения',
    campaignSettings: 'Настройка конкурса',
    campaignPeriod: 'Подача сведений',
    createRequest: 'Подать сведения',
    campaignDescription: 'Описание конкурса',
    campaignDescriptionEdit: 'Редактирование конкурса',

    campaignManagement: 'Управление конкурсом',

    delayedTask: 'Статус генерации',
    generationInProgress: 'Идет генерация',
    generationCompleted: 'Сгенерирован',
    generationFailed: 'Ошибка генерации',

    campaignManagementDelete: 'Вы действительно хотите удалить конкурс "{campaignName}"?',
    deleteCampaign: 'Удалить конкурс',
    campaignTitle: 'Управление конкурсом {campaignName}',

    createRequestError:
        'Вами уже создана заявка по данному конкурсу. Для просмотра и редактирования перейдите на вкладку Заявки',
};

export default campaign;
