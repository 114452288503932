import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { Root } from './Root';
import { env, features, locales, themeOverrides, history } from './initRootProps';
import createAppThemeOptions from './themes/appTheme';

ReactDOM.render(
    <Root
        env={env}
        features={features}
        locales={locales}
        themeOverrides={themeOverrides}
        createAppThemeOptions={createAppThemeOptions as any}
        history={history}
    />,
    document.getElementById('root'),
);
