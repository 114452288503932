import { Box, Button, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import {
    FooterContactInformation as FooterContactInformationInj,
    FooterTypography as FooterTypographyInj,
    getEmailFromText as getEmailFromTextInj,
    getPhoneNumberFromText as getPhoneNumberFromTextInj,
} from '@platform/ics-front-core';
import { useFeature } from 'feature-toggle-jsx';
import React, { useEffect, useState } from 'react';
import { di, injectable } from 'react-magnetic-di';
import styled from 'styled-components';
import { ReactComponent as MailIcon } from '../../../resources/images/icons/envelope.svg';
import { ReactComponent as PhoneIcon } from '../../../resources/images/icons/phone-alt.svg';

export const FooterButtonInj = withTheme(
    styled(Button)`
        .MuiButton-label {
            align-items: center;
        }

        .MuiTypography-root {
            font-weight: bold;
        }
    `,
);

export type FooterContactInformationProps = {
    phoneNumberText?: string;
    emailText?: string;
};

type EmailDataObj = { address: string; text: string };

export const FooterContactInformation = (props: FooterContactInformationProps): JSX.Element => {
    const [FooterButton] = di([FooterButtonInj], FooterContactInformation);
    const [FooterTypography] = di([FooterTypographyInj], FooterContactInformation);
    const [getEmailFromText] = di([getEmailFromTextInj], FooterContactInformation);
    const [getPhoneNumberFromText] = di([getPhoneNumberFromTextInj], FooterContactInformation);

    const [phoneNumber] = useFeature('phoneNumber');
    const [email] = useFeature('email');

    const { phoneNumberText = '', emailText = '' } = props;

    const [emails, setEmails] = useState<EmailDataObj[]>([]);

    useEffect(() => {
        // Разделяйте сообщения с помощью " | " (пробелы обязательны!)
        if (emailText.includes(' | ')) {
            const emailTexts = emailText.split(' | ').map((emailText) => {
                return { address: getEmailFromText(emailText), text: emailText };
            });
            setEmails(emailTexts);
        } else {
            setEmails([{ address: getEmailFromText(emailText), text: emailText }]);
        }
    }, [emailText]);

    const hrefTel = getPhoneNumberFromText(phoneNumberText);

    return (
        <Grid item>
            <Grid container direction="row" alignItems="center" justify="flex-end">
                {phoneNumber && hrefTel && (
                    <Grid item>
                        <Box pr={3}>
                            <FooterButton
                                component="a"
                                href={`tel:${hrefTel}`}
                                startIcon={<PhoneIcon />}
                                variant="text"
                                color="secondary"
                            >
                                <FooterTypography component="span">{phoneNumberText}</FooterTypography>
                            </FooterButton>
                        </Box>
                    </Grid>
                )}
                {email &&
                    emails.length > 0 &&
                    emails.map((email) => {
                        return (
                            <Grid item>
                                <FooterButton
                                    component="a"
                                    href={`mailto:${email.address}`}
                                    startIcon={<MailIcon />}
                                    variant="text"
                                    color="secondary"
                                >
                                    <FooterTypography component="span">{email.text}</FooterTypography>
                                </FooterButton>
                            </Grid>
                        );
                    })}
            </Grid>
        </Grid>
    );
};

export const FooterContactInformationDI = injectable(FooterContactInformationInj, (props) => (
    <FooterContactInformation {...props} />
));
