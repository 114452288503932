import { AxiosRequestConfig } from 'axios';

class ApiConfigs {
    generatePrintForm: (formId: string, templateId: string) => AxiosRequestConfig = (formId, templateId) => {
        return {
            method: 'POST',
            url: '/pf',
            params: {
                template: templateId,
                customForm: formId,
            },
        };
    };

    loadExecutors: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users/v2?role=Employee&role=SeniorEmployee&role=ModeratorRG',
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllActiveEmployees: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users?role=Employee&role=SeniorEmployee&role=ModeratorRG',
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllEmployees: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users?role=Employee&role=SeniorEmployee&role=ModeratorRG',
            method: 'GET',
        };
    };
}

export default new ApiConfigs();
