import overrides from '../common/common';
import { AppPalette, ThemeOverrides } from '../themeTypes';

type ColorName =
    | 'black'
    | 'white'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'red'
    | 'green'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled'
    | 'blue'
    | 'blueDark'
    | 'blueMiddle'
    | 'blueLight'
    | 'blueLighter'
    | 'blueWhite';

const mphaColors: Record<ColorName, string> = {
    black: '#000000',
    white: '#ffffff',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    red: '#fb4d3d',
    green: '#008000',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
    blue: '#475DA7',
    blueDark: '#28337C',
    blueMiddle: '#D6E3EC',
    blueLight: '#5C8EB3',
    blueLighter: '#AEC7D9',
    blueWhite: '#E7EFF4',
};

const mphaPalette: AppPalette = {
    main: mphaColors.greyMain,
    textMain: mphaColors.greyMain,
    textDark: mphaColors.black,
    mainMiddleLight: mphaColors.greyMiddleLight,
    mainLight: mphaColors.greyLight,
    mainDark: mphaColors.greyDark,
    mainContrast: mphaColors.white,
    mainContrastDarker: mphaColors.greyLighter,
    accent: mphaColors.blue,
    accentDark: mphaColors.blueDark,
    accentContrast: mphaColors.black,
    accentBackgroundGradient: `linear-gradient(-30deg, ${mphaColors.blue} 0%, ${mphaColors.blueDark} 100%)`,
    hover: mphaColors.blue,
    hoverInLists: mphaColors.greyWhite,
    red: mphaColors.red,
    green: mphaColors.green,
    campaignList: {
        backgroundCard: mphaColors.lighterBlue,
        selectedPaginationButton: mphaColors.lighterGrey,
    },
    table: {
        row: {
            even: mphaColors.white,
            odd: mphaColors.greyLighter,
        },
    },
    card: {
        header: {
            main: mphaColors.blueLight,
            color: mphaColors.white,
        },
    },
    appBar: {
        mainContrast: mphaColors.blue,
    },
    button: {
        accentContrast: mphaColors.white,
        disabled: mphaColors.disabled,
    },
    iconButton: {
        color: mphaColors.blue,
        hoverColor: mphaColors.blue,
        hoverBackgroundColor: mphaColors.blueWhite,
    },
    background: {
        textMain: mphaColors.black,
    },
    footer: {
        textMain: mphaColors.blueLighter,
        padding: 3.75,
    },
    tab: {
        backgroundColor: mphaColors.greyMiddleLight,
        color: mphaColors.blue,
        hoverBackgroundColor: mphaColors.blue,
        hoverColor: mphaColors.white,
    },
    panel: {
        markerBackgroundColor: 'rgba(53, 167, 255, 0.09)',
        markerColor: mphaColors.black,
    },

    accentContrastBackgroundGradient: mphaColors.white,
    mainBlue: mphaColors.blue,
    mainBlueMiddleLight: mphaColors.blueMiddle,
    mainBlueLight: mphaColors.blueLight,
    mainBlueDark: mphaColors.blueDark,
    mainBlueLighter: mphaColors.blueLighter,
    mainBlueWhite: mphaColors.blueWhite,
};

export default {
    ...overrides,
    palette: mphaPalette,
} as ThemeOverrides;
