export const entities = {
    System: 'System',
    User: 'User',
    Campaign: 'Campaign',
    CampaignRegForm: 'CampaignRegForm',
    CampaignPfTemplate: 'CampaignPfTemplate',
    CampaignRequest: 'CampaignRequest',
    RequestCustomForm: 'RequestCustomForm',
};

export const permissions = {
    System: {
        Administration: 'Administration',
        AddCampaign: 'AddCampaign',
        ViewCampaignList: 'ViewCampaignList',
        ViewCampRequestList: 'ViewCampRequestList',
        ExportCampRequestList: 'ExportCampRequestList',
        ViewPersonList: 'ViewPersonList',
        ViewReqFormList: 'ViewReqFormList',
        AddCampaignRequest: 'AddCampaignRequest',
        ViewCampReqExecutorColumn: 'ViewCampReqExecutorColumn',
    },
    User: {
        LoginAs: 'LoginAs',
        Update: 'Update',
    },
    Campaign: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        AddCampRequest: 'AddCampRequest',
        ApproveProvisionalAppointment: 'ApproveProvisionalAppointment',
    },
    CampaignRegForm: {
        Edit: 'Edit',
        Delete: 'Delete',
        EditCampRegFormSetting: 'EditCampRegFormSetting',
    },
    CampaignPfTemplate: {
        Edit: 'Edit',
        Delete: 'Delete',
    },
    CampaignRequest: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        UpdatePrintForm: 'UpdatePrintForm',
        Send: 'Send',

        CreateActualAppointment: 'CreateActualAppointment',
        UpdateActualAppointment: 'UpdateActualAppointment',
        AddActualAppointment: 'AddActualAppointment',
    },
};
