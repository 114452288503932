import { CampaignsStore as CampaignsStoreICS, EmployeeDTO, IdTitle, UserNameModel } from '@platform/ics-front-core';
import { action } from 'mobx';
import { injectable } from 'react-magnetic-di';
import apiConfigs from '../../apiConfigs';

export class CampaignsStore extends CampaignsStoreICS {
    @action.bound
    loadExecutors(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.loadExecutors()).then((r) => r.data);
    }

    @action.bound
    async fetchAllEmployee(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.getAllActiveEmployees())
            .then((r) => r.data)
            .then((employees) => {
                return employees.map((employee: EmployeeDTO) => {
                    const author = new UserNameModel().load({ ...employee, name: employee.person.firstName });
                    return {
                        id: employee.userId,
                        title: author.name,
                    };
                });
            });
    }
}

export const CampaignsStoreDI = injectable(CampaignsStoreICS, CampaignsStore);
