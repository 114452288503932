const campaignRequest: Record<string, Record<string, string> | string> = {
    registryTitle: 'Applications',
    RequestN: 'Application № {number}',
    registryColumns: {
        campaign: 'Competition',
    },
    registryPagingInfo: 'Total {count, number} applications. Displayed from {from, number} to {to, number}.',
    confirmDeletionInfoText: 'Are you sure you want to delete the application № {number}?',
    readonlyRequestValidationMsg: 'The application form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    printFormsTable: {
        cannotGenerateError: 'Printed form cannot be generated: application form is filled out incorrectly',
        cannotGenerateErrorWithLink:
            'Printed form cannot be generated: application form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    },
    createRequest: 'Create application',
    newRequest: 'New application',
    registryPagingInfoIfNull: 'Total 0 applications. Displayed 0 applications',
    deleteRequest: 'Delete application',
    actions: {
        requestToWork: 'Take application to work',
    },
    requestLog: 'Application log',
};

export default campaignRequest;