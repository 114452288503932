import { CampaignRequestListModel as CampaignRequestListModelICS, EmployeeDTO } from '@platform/ics-front-core';
import { action } from 'mobx';
import { injectable } from 'react-magnetic-di';
import apiConfigs from '../../../apiConfigs';

export class CampaignRequestListModel extends CampaignRequestListModelICS {
    @action.bound
    getEmployeesFilterData(): void {
        this.api
            .client(apiConfigs.getAllEmployees())
            .then((r) => r.data)
            .then((employees) => {
                const employeesIdTitle = employees.map((employee: EmployeeDTO) => ({
                    id: employee.userId,
                    title: `${employee.person.lastName} ${employee.person.firstName}`,
                }));
                this.employeeFilterData = employeesIdTitle;
            });
    }
}

export const CampaignRequestListModelDI = injectable(CampaignRequestListModelICS, CampaignRequestListModel);
