const campaignRequest: Record<string, Record<string, string> | string> = {
    registryTitle: 'Заявки',
    RequestN: 'Заявка № {number}',
    regFormCard: {
        registrationStartDate: 'Начало подачи',
        registrationEndDate: 'Окончание подачи',
    },
    createRequest: 'Подать заявку',
    regFormsPageTitle: 'Выбор формы подачи',
    registryColumns: {
        campaign: 'Услуга',
        number: 'Номер',
        regForm: 'Форма',
    },
    deleteRequest: 'Удалить',
    registryPagingInfo:
        'Всего {count, number} заяв{count, plural, one {ка} few {ки} other {ок}}. ' +
        'Отображены c {from, number} по {to, number}',
    registryPagingInfoIfNull: 'Всего 0 заявок. Отображено 0 заявок',
    printFormsTable: {
        crcCode: 'CRC-код',
    },
    errorTextForRequest:
        'Проверьте заполненность обязательных полей заявки и корректность введенных значений. Разделы, в которых содержатся ошибки, отмечены красным восклицательным знаком справа от анкеты заявки',
    readonlyRequestValidationMsg:
        'Заявка заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    dialog: {
        leaveMessage: 'Вы действительно хотите покинуть текущую заявку без сохранения изменений?',
    }
};

export default campaignRequest;
