const breadcrumbs: Record<string, string | Record<string, string>> = {
    campaigns: 'Направления',
    campaign: 'Направление',
    campaignSettings: 'Настройки направления',
    requests: 'Реестр инициатив',
    requestsNew: 'Реестр инициатив',
    regFormSelect: 'Выбор формы инициативы',
};

export default breadcrumbs;
