const campaignRequest: Record<string, Record<string, string> | string> = {
    printFormsTable: {
        crcCode: 'CRC-code',
    },
    deleteRequest: 'Delete',
    errorTextForRequest:
        'Check the completion of the required fields of the application and the correctness of the entered values. Sections with errors are marked with a red exclamation mark to the right of the application form',
    readonlyRequestValidationMsg: 'The form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    dialog: {
        leaveMessage: 'Are you sure you want to leave the current form without saving your changes?'
    }
};

export default campaignRequest;
