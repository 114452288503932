import {
    apiConfigs as apiConfigsICS,
    clientRoute,
    EmployeeDTO,
    handleAxiosErrorByResponseStatus,
    RequestDTO as RequestDTOICS,
    RequestFormDTO as RequestFormDTOICS,
    RequestStore as RequestStoreICS,
    TabDTO as TabDTOICS,
} from '@platform/ics-front-core';
import { action } from 'mobx';
import { injectable } from 'react-magnetic-di';
import apiConfigs from '../../apiConfigs';
import { CampaignRequestPfDTO } from '../models/campaign-request-pf/CampaignRequestPfModel';

export type RequestDTO = RequestDTOICS & {
    customTabs: TabDTO[];
};

export type TabDTO = TabDTOICS & {
    identifier: string;
};

export type RequestFormDTO = RequestFormDTOICS & {
    identifier: string;
};

export class RequestStore extends RequestStoreICS {
    @action.bound
    getRequestDTO(requestId: string): Promise<RequestDTO> {
        return this.api
            .client(apiConfigsICS.getRequest(requestId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    generatePrintForm(formId: string, templateId: string): Promise<CampaignRequestPfDTO> {
        return this.api.client(apiConfigs.generatePrintForm(formId, templateId)).then((r) => r.data);
    }

    @action.bound
    loadRequestCustomForm(customFormId: string): Promise<RequestFormDTO> {
        return this.api.client(apiConfigsICS.loadRequestForm(customFormId)).then((r) => r.data);
    }

    @action.bound
    getEmployeesList(): Promise<EmployeeDTO[]> {
        return this.api.client(apiConfigs.getAllActiveEmployees()).then((r) => r.data);
    }
}

export const RequestStoreDI = injectable(RequestStoreICS, RequestStore);
