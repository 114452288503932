function recordToLang(record: Record<string, any>): Record<string, string> {
    let result: Record<string, string> = {};

    function objectToDotNotation(obj: { [key: string]: any }, currentKey?: string) {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
            let value = obj[key];
            let newKey = currentKey ? `${currentKey}.${key}` : key; // joined key with dot
            if (value && typeof value === 'object') {
                objectToDotNotation(value, newKey); // it's a nested object, so do it again
            } else {
                result[newKey] = value; // it's not an object, so set the property
            }
        });
    }

    objectToDotNotation(record);
    return result;
}

export { recordToLang };
