import { Root as RootICS, RootStoreProps } from '@platform/ics-front-core';
import React from 'react';
import { Router } from 'react-router-dom';
import { FooterContactInformationDI } from './di/components/footer/FooterContactInformation';
import { CampaignRequestListModelDI } from './di/models/list/CampaignRequestListModel';
import { SelectRegFormDI } from './di/pages/campaign-request/campaign-request-list-page/campaign-requests-list-filters/CampaignRequestListFilterPanel';
import { CampaignRequestPrintFormsTableDi } from './di/pages/campaign-request/campaign-request-page/campaign-request-page-print-forms/campaign-request-page-print-form-table/CampaignRequestPrintFormsTable';
import { CampaignsStoreDI } from './di/store/CampaignsStore';
import { HeaderLinksStoreDI } from './di/store/HeaderLinksStore';
import { PersonStoreDI } from './di/store/PersonStore';
import { RequestStoreDI } from './di/store/RequestStore';

export const Root = (props: RootStoreProps): JSX.Element => {
    return (
        <Router history={props.history}>
            <RootICS
                {...props}
                use={[
                    PersonStoreDI,
                    RequestStoreDI,
                    CampaignRequestPrintFormsTableDi,
                    SelectRegFormDI,
                    HeaderLinksStoreDI,
                    FooterContactInformationDI,
                    CampaignRequestListModelDI,
                    CampaignsStoreDI,
                ]}
            />
        </Router>
    );
};
