import { recordToLang } from '../../utils';
import startPage from './startPage';
import homePage from './homePage';
import campaign from './campaign';
import campaignRequest from './campaignRequest';
import breadcrumbs from './breadcrumbs';

const lang: Record<string, string> = recordToLang({
    startPage,
    homePage,
    campaign,
    campaignRequest,
    breadcrumbs,
});

export default lang;
