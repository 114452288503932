const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Услуги',
    titleCreateCampaignPage: 'Создание услуги',
    noCategoriesText: 'В данный момент нет активных услуг для подачи заявки',
    newCampaign: 'Новая услуга',
    createCampaign: 'Создать услугу',
    editCampaign: 'Редактирование услуги',
    campaignRequests: 'Заявки',
    campaignSettings: 'Настройка услуги',
    campaignPeriod: 'Подача',
    createRequest: 'Подать заявку',
    campaignDescription: 'Описание услуги',
    campaignDescriptionEdit: 'Редактирование услуги',

    campaignManagement: 'Управление услугой',

    delayedTask: 'Статус генерации',
    generationInProgress: 'Идет генерация',
    generationCompleted: 'Сгенерирован',
    generationFailed: 'Ошибка генерации',

    campaignManagementDelete: 'Вы действительно хотите удалить услугу "{campaignName}"?',
    deleteCampaign: 'Удалить услугу',
    campaignTitle: 'Управление услугой {campaignName}',

    createRequestError:
        'Вами уже создана заявка по данной услуге. Для просмотра и редактирования перейдите на вкладку Заявки',
};

export default campaign;
