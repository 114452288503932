const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Направления',
    titleCreateCampaignPage: 'Создание направления',
    noCategoriesText: 'В данный момент нет активных направлений для подачи инициативы',
    newCampaign: 'Новое направление',
    createCampaign: 'Создать направление',
    editCampaign: 'Редактирование направления',
    campaignPeriod: 'Регистрация инициатив',
    createRequest: 'Направить инициативу',
    campaignRequests: 'Инициативы направления',
    campaignSettings: 'Настройка направления',

    campaignDescription: 'Описание направления',
    campaignDescriptionEdit: 'Редактирование направления',

    campaignManagement: 'Управление направлением',

    delayedTask: 'Статус генерации',
    generationInProgress: 'Идет генерация',
    generationCompleted: 'Сгенерирован',
    generationFailed: 'Ошибка генерации',

    campaignRegForms: 'Формы инициатив',
    campaignRegForm: '{count, plural, one {Форма} other {Формы}} инициативы',
    addCampaignRegFormButton: 'Добавить форму инициативы',
    newCampaignRegForm: 'Новая форма инициативы',
    editCampaignRegForm: 'Редактирование формы инициативы "{formTitle}"',
    deleteCampaignRegForm: 'Удаление формы инициативы',
    confirmDeleteCampaignRegForm: 'Вы действительно хотите удалить форму инициативы "{formTitle}"?',

    campaignManagementDelete: 'Вы действительно хотите удалить направление "{campaignName}"?',
    deleteCampaign: 'Удалить направление',
    campaignTitle: 'Управление направлением {campaignName}',

    templateForm: {
        regForms: 'Формы инициатив',
    },
    requestFormSettingsTitle: 'Настройка вкладок для инициативы',
    campaignRegFormExecutorRequestTab: 'Вкладка инициативы',
    requestTabLabel: 'Вкладка инициативы',

    createRequestError:
        'Вами уже создана инициатива по данному направлению. Для просмотра и редактирования перейдите на вкладку инициативы',
};

export default campaign;
